import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { fCurrency, fPercent } from '@bakerweb/utils';
import { ValidPosition } from '@bakerweb/models';
import { useEffect, useState } from 'react';
import { useGetPortfolioEmails, useSendSecuritiesEmail } from '@bakerweb/client-services';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { selectedPortfolioState } from '@bakerweb/client-state';

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
  selectedPositions: ValidPosition[];
};

export function SecuritiesEmailBrokerDialog({ isOpen, closeDialog, selectedPositions }: Props) {
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioCode = portfolio?.portfolioCode;
  const { portfolioEmails } = useGetPortfolioEmails();
  const brokerEmails = portfolioEmails.map((email) => email.employeeEmail);
  const { sendEmail } = useSendSecuritiesEmail();
  const [selectedBrokerEmails, setSelectedBrokerEmails] = useState<string[]>(brokerEmails);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (portfolioEmails.length > 0) {
      setSelectedBrokerEmails(brokerEmails);
    }
  }, [portfolioEmails]);

  const handlePortfolioEmailsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    setSelectedBrokerEmails(typeof value === 'string' ? value.split(',') : value);
  };

  const handleConfirmSendEmail = async () => {
    if (!portfolioCode) {
      toast.error('Please select a portfolio code before attempting to send this email.');
      return;
    }
    if (!selectedBrokerEmails || selectedBrokerEmails.length <= 0) {
      toast.error('Please select email recipients before attempting to send this email.');
    }
    await sendEmail({
      emailTo: brokerEmails.join(';'),
      positions: selectedPositions.map(({ positionId }) => ({ positionId })),
      notes,
      portfolioCode
    });
    setSelectedBrokerEmails(brokerEmails);
    closeDialog();
    setNotes('');
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="md" fullWidth>
      <DialogTitle sx={{ mb: 2, ml: 2 }} color={'error'}>
        Email Baker
      </DialogTitle>
      <Box sx={{ ml: 4, mb: 2 }}>
        <Select
          labelId="broker-email-select-label"
          multiple
          value={selectedBrokerEmails}
          onChange={handlePortfolioEmailsChange}
          label="Select Broker Email"
          sx={{ width: 500 }}
        >
          {portfolioEmails.map((portfolioEmail) => (
            <MenuItem key={portfolioEmail.recId} value={portfolioEmail.employeeEmail}>
              {portfolioEmail.employeeEmail}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ ml: 4, mb: 2 }}>
        <TextField
          label="Notes"
          multiline
          rows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          sx={{ width: 500 }}
        />
      </Box>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CUSIP</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Maturity</TableCell>
              <TableCell>CPN</TableCell>
              <TableCell>Current Face</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedPositions.map((row) => (
              <TableRow key={JSON.stringify(row)}>
                <TableCell>{row.cusip}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.maturityDate}</TableCell>
                <TableCell>{fPercent(row.coupon)}</TableCell>
                <TableCell>{fCurrency(row.currFace)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeDialog();
            setNotes('');
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirmSendEmail}>
          Confirm and Send Email
        </Button>
      </DialogActions>
    </Dialog>
  );
}
