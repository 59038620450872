import { useQuery } from '@apollo/client';
import { selectedPortfolioState } from '@bakerweb/client-state';
import { AsOfDate } from '@bakerweb/models';
import { useRecoilValue } from 'recoil';
import { GET_AS_OF_DATES } from '../graphql/queries/getAsOfDates';

export const useGetAsOfDates = () => {
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;

  const { data, loading, error } = useQuery<{ asOfDates: AsOfDate[] }>(GET_AS_OF_DATES, {
    variables: { portfolioId },
    skip: !portfolio,
    fetchPolicy: 'cache-first'
  });

  return { loading, error, asOfDates: data?.asOfDates ?? [] };
};
