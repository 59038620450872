import { selectedPortfolioState } from '@bakerweb/client-state';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { clientHttp } from '../client.http';

interface PledgeeReportPayload {
  pledgeCode: string | null;
  asOfDate: string;
}

export const useEmailPledgeeReport = () => {
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioCode = portfolio?.portfolioCode;
  const [isLoading, setIsLoading] = useState(false);

  const sendReport = useCallback(async (data: PledgeeReportPayload) => {
    const session = await fetchAuthSession();
    const emailTo = session?.tokens?.idToken?.payload?.['email'];
    setIsLoading(true);
    try {
      await clientHttp('/position/report/pledgee', {
        method: 'POST',
        data: {
          pledgeCode: data.pledgeCode,
          asOfDate: data.asOfDate,
          portfolioCode,
          emailTo
        }
      });
      toast.success('Report sent successfully!');
    } catch (error) {
      toast.error('Failed to send report.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    sendReport,
    isLoading
  };
};
