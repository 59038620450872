import { addDays, format, formatDistanceToNow, getTime, isValid } from 'date-fns';

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateStandard(date: Date | string | number | undefined) {
  if (!date) {
    return '';
  }
  const parsedDate = new Date(date);
  if (!isValid(parsedDate)) {
    return '';
  }
  const isUTC = typeof date === 'string' && date.endsWith('Z');
  if (isUTC) {
    // Add a day if the date is in UTC format
    return format(addDays(parsedDate, 1), 'MM/dd/yyyy');
  }

  return format(parsedDate, 'MM/dd/yyyy');
}

export function fAsOfDate(date: string) {
  if (!date) {
    return '';
  }
  return date;
}

export function fDateToISODate(date: Date | string) {
  const dateFormat = 'yyyy-MM-dd';
  if (!date) {
    return '';
  }
  return format(new Date(date), dateFormat);
}

export function fDateToLocalString(date: Date | string | number) {
  return new Date(date).toLocaleDateString();
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function getMonthDifference(startDate: Date, endDate: Date): number {
  return (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
}
