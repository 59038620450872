import { GridAutosizeOptions, GridValidRowModel } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

const defaultAutosizeOptions: GridAutosizeOptions = {
  includeOutliers: true,
  includeHeaders: true,
  outliersFactor: 1.5
};

// Enable automatic resizing of column widths in MUI DataGrid after dynamic loading of data.
export const useAutosize = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  rows: GridValidRowModel[],
  overrideOptions?: GridAutosizeOptions
) => {
  const autosizeOptions = useMemo(() => overrideOptions || defaultAutosizeOptions, []);

  const autosizeColumns = useCallback(() => {
    apiRef.current.autosizeColumns(autosizeOptions);
  }, [apiRef, autosizeOptions]);

  useEffect(() => {
    // Reason for this is documented here: https://github.com/goBakerSolutions/BakerWeb/pull/826#discussion_r1920445633
    const timeoutId = setTimeout(() => {
      ReactDOM.flushSync(() => {
        autosizeColumns();
      });
    }, 1000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [rows, autosizeColumns]);
};
