import { ValidPosition } from '@bakerweb/models';
import { alpha, Theme } from '@mui/material';
import { isSameMonth, isSameYear } from 'date-fns';

export function computeDisposedValue(position: ValidPosition) {
  const { disposedTransCode, disposedRemoved } = position;

  if (!disposedTransCode) return 'No';

  const isDisposed = ['S', 'PS', 'CC', 'PC', 'M', 'PO', 'PR', 'SK'].includes(disposedTransCode) && !disposedRemoved;

  return isDisposed ? 'Yes' : 'No';
}

export function computePurchasedValue(position: ValidPosition, selectedAsOfDateFormatted: string) {
  const { purchaseDate, isBackPurchase, backPurchaseEntryDate } = position;
  if (!selectedAsOfDateFormatted) return false;

  const asOfDate = new Date(selectedAsOfDateFormatted);

  // Check if purchaseDate is in the same month and year as asOfDate
  const isPurchasedInSameMonthAndYear = purchaseDate
    ? isSameMonth(new Date(purchaseDate), asOfDate) && isSameYear(new Date(purchaseDate), asOfDate)
    : false;

  // Check if backPurchaseEntryDate is in the same month and year if isBackPurchase is true
  const isBackPurchasedInSameMonthAndYear =
    isBackPurchase && backPurchaseEntryDate
      ? isSameMonth(new Date(backPurchaseEntryDate), asOfDate) && isSameYear(new Date(backPurchaseEntryDate), asOfDate)
      : false;

  return isPurchasedInSameMonthAndYear || isBackPurchasedInSameMonthAndYear;
}

export const purchasedRowColor = (theme: Theme) => ({
  backgroundColor: alpha(theme.palette.success.main, 0.08)
});

export const disposedRowColor = (theme: Theme) => ({
  backgroundColor: alpha(theme.palette.error.main, 0.08)
});
