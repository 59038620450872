import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { UPDATE_POSITIONS } from '../graphql/mutations/updatePositions';

export const useUpdatePositions = () => {
  const [updatePositionsMutation, { data, loading, error }] = useMutation(UPDATE_POSITIONS);

  const updatePositions = async (positionUpdateDto) => {
    if (!positionUpdateDto || positionUpdateDto.length === 0) {
      toast.error('No positions provided to update.');
      return;
    }
    try {
      await updatePositionsMutation({
        variables: { positionUpdateDto }
      });
      toast.success('Positions updated successfully');
    } catch (err) {
      toast.error('There was an error updating positions.');
      console.error('Error updating positions:', err);
    }
  };

  return { updatePositions, data, loading, error };
};
