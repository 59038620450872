import { styled, useTheme } from '@mui/material/styles';
import ApexCharts from 'apexcharts';

const CHART_HEIGHT = 400;
const LEGEND_HEIGHT = 72;

export const ChartWrapperStyle = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas': {
    height: '100% !important', // Ensure ApexCharts respects the wrapper height
    maxHeight: '100% !important'
  },
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    position: 'relative !important' as 'relative',
    overflow: 'hidden',
    alignContent: 'center',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

export function BaseOptionChart(): ApexCharts.ApexOptions {
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: 'Total',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle2.fontSize as string,
    fontWeight: theme.typography.subtitle2.fontWeight
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize as string,
    fontWeight: theme.typography.h3.fontWeight
  };

  return {
    colors: [
      theme.palette.primary.main,
      theme.palette.chart.yellow[0],
      theme.palette.chart.blue[0],
      theme.palette.chart.violet[0],
      theme.palette.chart.green[0],
      theme.palette.chart.red[0]
    ],
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily
    },
    states: {
      hover: { filter: { type: 'lighten' } },
      active: { filter: { type: 'darken' } }
    },
    fill: {
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    dataLabels: { enabled: false },
    stroke: { width: 3, curve: 'smooth', lineCap: 'round' },
    grid: { strokeDashArray: 3, borderColor: theme.palette.divider },
    xaxis: { axisBorder: { show: false }, axisTicks: { show: false } },
    markers: { size: 0, strokeColors: theme.palette.background.paper },
    tooltip: { x: { show: false } },
    legend: {
      show: true,
      fontSize: '13px',
      position: 'bottom',
      horizontalAlign: 'center',
      markers: { size: 6, shape: 'circle' },
      fontWeight: 500,
      labels: { colors: theme.palette.text.primary }
    },
    plotOptions: {
      bar: { columnWidth: '28%', borderRadius: 4 },
      pie: { donut: { labels: { show: true, value: LABEL_VALUE, total: LABEL_TOTAL } } },
      radialBar: {
        track: { strokeWidth: '100%', background: theme.palette.grey[500] },
        dataLabels: { value: LABEL_VALUE, total: LABEL_TOTAL }
      },
      radar: {
        polygons: {
          fill: { colors: ['transparent'] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider
        }
      },
      polarArea: {
        rings: { strokeColor: theme.palette.divider },
        spokes: { connectorColors: theme.palette.divider }
      }
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        options: { plotOptions: { bar: { columnWidth: '40%' } } }
      },
      {
        breakpoint: theme.breakpoints.values.md,
        options: { plotOptions: { bar: { columnWidth: '32%' } } }
      }
    ]
  };
}
