import { useGetSkInstitutions, useMigrateSkPositions } from '@bakerweb/client-services';
import { selectedAsOfDateState } from '@bakerweb/client-state';
import { SafeKeepingCode } from '@bakerweb/models';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Iconify } from '../iconify/Iconify';

export function SafekeepingForm() {
  const [toSkCode, setToSkCode] = useState<SafeKeepingCode | null>(null);
  const [fromSkCode, setFromSkCode] = useState<SafeKeepingCode | null>(null);
  const [canSave, setCanSave] = useState(false);
  const asOfDate = useRecoilValue(selectedAsOfDateState);
  const { safeKeepingInstitutions } = useGetSkInstitutions();
  const { migrateSkPositions } = useMigrateSkPositions();

  const toggleCanSave = () => {
    if (toSkCode && fromSkCode && asOfDate) {
      setCanSave(!canSave);
    }
  };

  const handleSave = async () => {
    if (fromSkCode && toSkCode && asOfDate && canSave) {
      try {
        await migrateSkPositions({
          from: fromSkCode.safeKeepingCode,
          to: toSkCode.safeKeepingCode,
          asOfDate
        });
      } catch (error) {
        console.error('Error migrating SK positions:', error);
      }
    } else {
      toast.error('Please fill out all fields to proceed.');
    }
  };

  const getOptionLabel = useCallback(
    (option: SafeKeepingCode) => `${option.safeKeepingName} (${option.safeKeepingCode})`,
    []
  );

  return (
    <Box>
      <Typography id="Global SK Modal Header" variant="h6" component="h2" sx={{ mb: 2 }}>
        Change Safekeeping
      </Typography>
      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <Autocomplete
          id="fromSKCode"
          options={safeKeepingInstitutions}
          getOptionLabel={getOptionLabel}
          value={fromSkCode}
          onChange={(event, newValue) => {
            setFromSkCode(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="From Safekeeping" />}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Autocomplete
          id="toSKCode"
          options={safeKeepingInstitutions}
          getOptionLabel={getOptionLabel}
          value={toSkCode}
          onChange={(event, newValue) => {
            setToSkCode(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="To Safekeeping" />}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox checked={canSave} onChange={toggleCanSave} />}
          label="I am aware this change will affect all selected global safekeeping codes."
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={!canSave}
          startIcon={<Iconify icon="eva:checkmark-circle-outline" />}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      </FormControl>
    </Box>
  );
}
